import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header({ width }) {
  return window.location.pathname === "/" ? (
    <PageHeader
      title="Notifi"
      subTitle={width > 1023 ? "An Ethereum messaging service" : ""}
      style={{ width: "100%", paddingBottom: "0rem" }}
    />
  ) : (
    <PageHeader
      title="Notifi"
      subTitle={width > 1023 ? "An Ethereum messaging service" : ""}
      style={{ width: "100%", paddingBottom: "0rem" }}
      onBack={() => {
        if (window.history.state) {
          window.history.back();
        } else {
          window.location.href = "/";
        }
      }}
    />
  );
}
