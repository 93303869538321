import { Card, Popover } from "antd";
import { CommentOutlined, HeartOutlined, HeartFilled, ShareAltOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Blockies from "react-blockies";
import { getAvatar } from "../helpers/ens.js";

/*
  ~ What it does? ~

  Displays a single on chain message given transaction data.

  ~ How can I use? ~

  <Message
    transaction={transaction}
  />
*/

export default function Message({
  transaction,
  user,
  userFavorites,
  setUserFavorites,
  apiUrl,
  apiToken,
  setRoute,
  messageDetailPage,
  provider,
  logoutOfWeb3Modal,
}) {
  const { from, to, message, createdAt, hash, blockNumber, id: transactionId, users } = transaction;
  const matchedFavorite = userFavorites
    ? userFavorites.find(fav => (fav ? fav.transactionId === transactionId : false))
    : {};

  let formattedMessage;
  if (message) {
    formattedMessage = formatMessage(message.replace(" --- Message sent with https://notifi.xyz", ""), 0);
  }

  const [shareButtonClicked, setShareButtonClicked] = useState(false);
  const [shareButtonHovered, setShareButtonHovered] = useState(false);
  const [favoriteButtonHovered, setFavoriteButtonHovered] = useState(false);
  const [fromAvatar, setFromAvatar] = useState();
  const [toAvatar, setToAvatar] = useState();
  const [fromEns, setFromEns] = useState();
  const [toEns, setToEns] = useState();
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (users && users.from && users.from.ensData) {
      if (users.from.ensData.avatar) {
        getAvatar(provider, users.from.ensData.name).then(avatar => {
          if (componentMounted.current) {
            setFromAvatar(avatar);
          }
        });
      }
      if (users.from.ensData.name) {
        setFromEns(users.from.ensData.name);
      }
    }
    if (users && users.to && users.to.ensData) {
      if (users.to.ensData.avatar) {
        getAvatar(provider, users.to.ensData.name).then(avatar => {
          if (componentMounted.current) {
            setToAvatar(avatar);
          }
        });
      }
      if (users.to.ensData.name) {
        setToEns(users.to.ensData.name);
      }
    }
  }, [users]);

  function formatImage(image) {
    return (
      <Card
        bordered={false}
        style={{
          width: "50%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
          background: "rgb(40, 40, 40)",
        }}
      >
        <img
          src={image}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",
            width: "50%",
          }}
        />
      </Card>
    );
  }

  function formatMessage(rawMessage) {
    const complexElements = [...rawMessage.matchAll(/<svg|.gif|.png|.jpg|.jpeg|http:\/\/|https:\/\//g)];
    let message = [];
    let bottomText = "";

    let curMessageIndex = 0;
    complexElements.forEach(match => {
      if (match[0] === "<svg") {
        const svgStart = match.index;
        const svgEnd =
          rawMessage.indexOf("/svg>", curMessageIndex) > svgStart && rawMessage.indexOf("/svg>", curMessageIndex) + 4;

        if (svgStart < curMessageIndex) {
          return;
        }

        if (svgEnd) {
          const complexMsg = formatImage(
            `data:image/svg+xml;utf8,${encodeURIComponent(rawMessage.substring(svgStart, svgEnd + 1))}`,
          );
          const topText = rawMessage.substring(curMessageIndex, svgStart);
          bottomText = rawMessage.substring(svgEnd + 1, rawMessage.length);
          message.push(
            <div key={match.index} style={{ whiteSpace: "pre-wrap" }}>
              {topText ? topText.trim() : ""}
              {complexMsg}
            </div>,
          );
        }
        curMessageIndex = svgEnd + 1;
      } else if ([".gif", ".png", ".jpg", ".jpeg"].includes(match[0])) {
        const imgStart = rawMessage.indexOf("http", curMessageIndex);
        const imgEnd = match.index + (match[0].length - 1);

        if (imgStart < curMessageIndex) {
          return;
        }

        if (imgStart) {
          const complexMsg = formatImage(rawMessage.substring(imgStart, imgEnd + 1));
          const topText = rawMessage.substring(curMessageIndex, imgStart);
          bottomText = rawMessage.substring(imgEnd + 1, rawMessage.length);
          message.push(
            <div key={match.index} style={{ whiteSpace: "pre-wrap" }}>
              {topText ? topText.trim() : ""}
              {complexMsg}
            </div>,
          );
        }
        curMessageIndex = imgEnd + 1;
      }
    });

    if (!message.length) {
      message.push(
        <div key="0" style={{ whiteSpace: "pre-wrap" }}>
          {rawMessage.trim()}
        </div>,
      );
    } else {
      message.push(
        <div key="end" style={{ whiteSpace: "pre-wrap" }}>
          {bottomText ? bottomText.trim() : ""}
        </div>,
      );
    }

    return message;
  }

  async function favoriteMessage(method) {
    let options = {};
    let url = "";

    if (method === "POST") {
      options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken || window.localStorage.getItem("apiToken")}`,
        },
        body: JSON.stringify({
          userId: user.id,
          transactionId,
        }),
      };
      url = `${apiUrl}/api/favoriteTransactions`;
    } else if (method === "DELETE") {
      options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken || window.localStorage.getItem("apiToken")}`,
        },
      };
      url = `${apiUrl}/api/favoriteTransactions/${matchedFavorite.id}`;
    }

    await fetch(url, options)
      .then(async res => {
        if (!res || res.status !== 200) {
          if (res.status === 401) {
            // TODO improve expired token handling
            return logoutOfWeb3Modal("Token expired, please reconnect your wallet.");
          }
          throw new Error(res);
        }
        const body = await res.json();

        if (componentMounted.current) {
          if (method === "POST") {
            setUserFavorites(userFavorites => [body, ...userFavorites]);
          } else if (method === "DELETE") {
            const newUserFavorites = userFavorites.filter(fav => fav.transactionId !== transactionId);
            setUserFavorites(newUserFavorites);
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).catch(error => {
      console.error(error);
    });
  }

  const history = useHistory();

  const display = (
    <Card
      hoverable={messageDetailPage ? false : true}
      bordered={true}
      style={{
        marginTop: 16,
        marginBottom: 16,
        background: "rgb(40, 40, 40)",
      }}
      actions={[
        // <CommentOutlined key="comment" onClick={() => console.log("comment button clicked")} />,
        user && apiToken ? (
          matchedFavorite ? (
            <div
              onClick={() => {
                favoriteMessage("DELETE");
                transaction.fav_count = transaction.fav_count - 1;
              }}
            >
              <HeartFilled key="favorite" /> {transaction.fav_count !== undefined ? `(${transaction.fav_count})` : ""}
            </div>
          ) : (
            <div
              onClick={() => {
                favoriteMessage("POST");
                transaction.fav_count = transaction.fav_count + 1;
              }}
            >
              <HeartOutlined key="favorite" /> {transaction.fav_count !== undefined ? `(${transaction.fav_count})` : ""}
            </div>
          )
        ) : (
          <Popover
            style={{ width: 500 }}
            content="Connect wallet to like messages"
            trigger="hover"
            visible={favoriteButtonHovered}
            onVisibleChange={() => setFavoriteButtonHovered(!favoriteButtonHovered)}
          >
            <div>
              <HeartOutlined key="favorite" disabled />{" "}
              {transaction.fav_count !== undefined ? `(${transaction.fav_count})` : ""}
            </div>
          </Popover>
        ),
        <Popover
          style={{ width: 500 }}
          content="Copy direct link to message"
          trigger="hover"
          visible={shareButtonHovered}
          onVisibleChange={() => setShareButtonHovered(!shareButtonHovered)}
        >
          <Popover
            content="Link to message copied"
            trigger="click"
            visible={shareButtonClicked}
            onVisibleChange={() => {
              setShareButtonHovered(!shareButtonHovered);
              setShareButtonClicked(true);
              setInterval(() => {
                setShareButtonClicked(false);
              }, 2000);
            }}
          >
            <ShareAltOutlined
              key="share"
              onClick={() => copyToClipboard(`${window.location.origin}/messages/${transactionId}`)}
            />
          </Popover>
        </Popover>,
      ].filter(action => action)}
    >
      <div
        onClick={() => {
          if (!messageDetailPage) {
            setRoute(`/messages/${transactionId}`);
            history.push(`/messages/${transactionId}`);
          }
        }}
      >
        {formattedMessage ? (
          <div style={{ textAlign: "left" }}>
            {new Date(createdAt).toLocaleString("en-US")}
            <br />
            <b>Block Number</b>:{" "}
            <a
              href={"https://etherscan.io/block/" + blockNumber}
              target="_blank"
              rel="noreferrer noopener"
              style={{ maxWidth: "50%", overflowWrap: "anywhere" }}
              onClick={e => e.stopPropagation()}
            >
              {blockNumber}
            </a>{" "}
            <br />
            <br />
            <i
              style={{
                overflowWrap: "anywhere",
                marginLeft: "1.6rem",
                marginRight: "1.6rem",
                display: "grid",
                fontSize: "1.5rem",
                maxHeight: "80rem",
                overflow: "scroll",
              }}
            >
              {formattedMessage}
            </i>
            <br />
            <b>Sender</b>:{" "}
            <Link
              to={"/profile/" + from}
              style={{ maxWidth: "50%", overflowWrap: "anywhere" }}
              onClick={e => e.stopPropagation()}
            >
              {fromAvatar ? (
                <img style={{ width: "16px", height: "16px" }} src={fromAvatar} />
              ) : (
                <Blockies size={4} scale={4} seed={from.toLowerCase()} />
              )}{" "}
              {fromEns || from}
            </Link>{" "}
            <br />
            <b>Receiver</b>:{" "}
            <Link
              to={"/profile/" + to}
              style={{ maxWidth: "50%", overflowWrap: "anywhere" }}
              onClick={e => e.stopPropagation()}
            >
              {toAvatar ? (
                <img style={{ width: "16px", height: "16px" }} src={toAvatar} />
              ) : (
                <Blockies size={4} scale={4} seed={to.toLowerCase()} />
              )}{" "}
              {toEns || to}
            </Link>{" "}
            <br />
            <b>Transaction</b>:{" "}
            <a
              href={"https://etherscan.io/tx/" + hash}
              target="_blank"
              rel="noreferrer noopener"
              style={{ maxWidth: "50%", overflowWrap: "anywhere" }}
              onClick={e => e.stopPropagation()}
            >
              {hash}
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );

  return message ? <div>{display}</div> : "";
}
