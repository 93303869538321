import { Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Blockies from "react-blockies";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useLookupAddress } from "eth-hooks/dapps/ens";
import { getEnsData } from "../helpers/ens.js";

// changed value={address} to address={address}

/*
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const { Text } = Typography;

const blockExplorerLink = (address, blockExplorer) =>
  `${blockExplorer || "https://etherscan.io/"}${"address/"}${address}`;

export default function Address(props) {
  const [address, setAddress] = useState();
  const [ensName, setEnsName] = useState();
  const [avatar, setAvatar] = useState();

  const lookupAddress = useLookupAddress(props.ensProvider, address);

  useEffect(() => {
    const ensData = (props.user && props.user.ensData) || {};
    const address = props.value || props.address;
    setAvatar(ensData.avatar);
    setAddress(address);
    setEnsName(ensData.name || lookupAddress);
  }, [props.address]);

  useEffect(() => {
    if (avatar) {
      return;
    }
    if (ensName) {
      getEnsData(props.ensProvider, ensName, address).then(({ _address, _ens, freshEnsData }) => {
        if (freshEnsData && freshEnsData.avatar) {
          setAvatar(freshEnsData.avatar);
        }
      });
    }
  }, [ensName]);

  const { currentTheme } = useThemeSwitcher();

  if (!address) {
    return (
      <span>
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </span>
    );
  }

  let displayAddress = address.substr(0, 6);

  const ensSplit = ensName && ensName.split(".");
  const validEnsCheck = ensSplit && ensSplit[ensSplit.length - 1] === "eth";

  if (validEnsCheck) {
    displayAddress = ensName;
  } else if (props.size === "short") {
    displayAddress += "..." + address.substr(-4);
  } else if (props.size === "long") {
    displayAddress = address;
  }

  const etherscanLink = blockExplorerLink(address, props.blockExplorer);
  if (props.minimized) {
    return (
      <span style={{ verticalAlign: "middle" }}>
        <a style={{ color: "#ddd" }} target="_blank" href={etherscanLink} rel="noopener noreferrer">
          {avatar ? avatar : <Blockies seed={address.toLowerCase()} size={8} scale={2} />}
        </a>
      </span>
    );
  }

  let text;
  if (props.onChange) {
    text = (
      <Text editable={{ onChange: props.onChange }} copyable={{ text: address }}>
        <a style={{ color: "#ddd" }} target="_blank" href={etherscanLink} rel="noopener noreferrer">
          {displayAddress}
        </a>
      </Text>
    );
  } else {
    text = (
      <Text copyable={{ text: address }}>
        <a style={{ color: "#ddd" }} target="_blank" href={etherscanLink} rel="noopener noreferrer">
          {displayAddress}
        </a>
      </Text>
    );
  }

  return (
    <span>
      <span style={{ verticalAlign: "middle" }}>
        {avatar ? (
          <img
            src={avatar}
            width={(props.fontSize ? props.fontSize / 7 : 4) * 8}
            height={(props.fontSize ? props.fontSize / 7 : 4) * 8}
            style={{ borderRadius: "50%" }}
          />
        ) : (
          <Blockies seed={address.toLowerCase()} size={8} scale={props.fontSize ? props.fontSize / 7 : 4} />
        )}
      </span>
      <span style={{ verticalAlign: "middle", paddingLeft: 5, fontSize: props.fontSize ? props.fontSize : 20 }}>
        {text}
      </span>
    </span>
  );
}
