import { Input } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Blockie from "./Blockie";
import { getEnsData } from "../helpers/ens.js";

const defaultAddress = "0x940d9b3b0cdb662345c0aa7db96d32eb8abaf359";

/*
  ~ What it does? ~

  Displays an address input with QR scan option

  ~ How can I use? ~

  <AddressInput
    autoFocus
    ensProvider={mainnetProvider}
    placeholder="Enter address"
    value={toAddress}
    onChange={setToAddress}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth") or you can enter directly ENS name instead of address
  - Provide placeholder="Enter address" value for the input
  - Value of the address input is stored in value={toAddress}
  - Control input change by onChange={setToAddress}
                          or onChange={address => { setToAddress(address);}}
*/

export default function AddressInput(props) {
  const [value, setValue] = useState();
  const [ensName, setEnsName] = useState();
  const [receiverAvatar, setReceiverAvatar] = useState();
  const [addressDetails, setAddressDetails] = useState();
  const timeIdRef = useRef(null);

  const { ensProvider, onChange, setEnsIsLoading } = props;

  useEffect(() => {
    if (props.address !== defaultAddress) {
      setValue(props.address);
    } else {
      setValue();
    }
  }, [props.address]);

  useEffect(() => {
    if (!value) {
      setEnsName();
      setReceiverAvatar();
    }
  }, [value]);

  const updateAddress = useCallback(
    async newValue => {
      if (timeIdRef.current) clearTimeout(timeIdRef.current);
      let address = newValue;
      if (typeof newValue !== "undefined") {
        if (address.indexOf(".eth") > 0 || address.indexOf(".xyz") > 0) {
          try {
            const timeOutId = setTimeout(() => {
              setEnsIsLoading(true);
              getEnsData(ensProvider, address).then(({ address, ens, ensData }) => {
                if (timeIdRef.current === timeOutId) {
                  setAddressDetails(address ? null : "ENS name can not be resolved");
                  setEnsName(ens);
                  setReceiverAvatar(ensData.avatar || "");
                  setEnsIsLoading(false);
                  if (typeof onChange === "function") {
                    onChange(address);
                  }
                }
              });
            }, 100);
            timeIdRef.current = timeOutId;
          } catch (e) {}
        }
      }
      setValue();
      setEnsName();
      setReceiverAvatar();
      if (typeof onChange === "function") {
        onChange(address);
      }
    },
    [ensProvider, onChange],
  );

  return (
    <div>
      <Input
        id="0xAddress" // name it something other than address for auto fill doxxing
        name="0xAddress" // name it something other than address for auto fill doxxing
        autoComplete="off"
        autoFocus={props.autoFocus}
        placeholder={props.placeholder ? props.placeholder : "address"}
        allowClear={true}
        prefix={
          receiverAvatar ? (
            <img
              src={receiverAvatar}
              width={(props.fontSize ? props.fontSize / 7 : 3) * 8}
              height={(props.fontSize ? props.fontSize / 7 : 3) * 8}
            />
          ) : (
            <Blockie address={value} size={8} scale={3} />
          )
        }
        value={ensName || value}
        onChange={e => {
          updateAddress(e.target.value);
        }}
      />
      {addressDetails ? addressDetails : ""}
    </div>
  );
}
