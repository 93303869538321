import { Button, Card, Dropdown, Input, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Message from "./Message";
import { isEthAddress } from "../helpers/utils.js";

const { Search } = Input;

/*
  ~ What it does? ~

  Displays a list of on chain messages given a list of transaction data.

  ~ How can I use? ~

  <Message
    transactionList={transactionList}
    messageOffset={messageOffset}
    totalMessages={totalMessages}
  />
*/

export default function MessageList({
  transactionList,
  messageOffset,
  setMessageOffset,
  totalMessages,
  user,
  userFavorites,
  setUserFavorites,
  apiUrl,
  apiToken,
  setRoute,
  setSortMethod,
  messageDetailPage,
  logoutOfWeb3Modal,
  provider,
  width,
}) {
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search for ETH address or ENS name");

  const sortMenu = (
    <Menu>
      <Menu.Item key="default" onClick={() => setSortMethod("default")}>
        Most Recent
      </Menu.Item>
      <Menu.Item key="favoriteCount" onClick={() => setSortMethod("favoriteCount")}>
        Most Popular
      </Menu.Item>
    </Menu>
  );
  const history = useHistory();

  const onSearch = async value => {
    let ethAddress = value;
    if (value.endsWith(".eth")) {
      ethAddress = await provider.resolveName(value);
    }
    if (isEthAddress(ethAddress)) {
      setRoute(`/profile/${ethAddress.toLowerCase()}`);
      history.push(`/profile/${ethAddress.toLowerCase()}`);
      return;
    }
    // TODO handle invalid address
    setSearchPlaceholder("Invalid ETH address.");
    value = null;
    console.log("invalid eth address");
  };

  const display = (
    <div>
      {/* TODO fix load more offset/order by <Dropdown overlay={sortMenu}>
        <div
          style={{
            cursor: "pointer",
            float: "right",
            display: "inline-block",
            marginRight: "2rem",
            marginTop: "2rem",
          }}
          className="ant-dropdown-link"
          onClick={e => e.preventDefault()}
        >
          Sort Messages <DownOutlined />
        </div>
      </Dropdown> */}
      <main style={{ paddingTop: "1rem", paddingBottom: "5.2rem" }}>
        <div
          style={{
            alignContent: "center",
            boxSizing: "border-box",
            maxWidth: "80%",
            margin: "0 auto",
          }}
        >
          {window.location.pathname === "/" && (
            <div>
              <Card
                bordered={false}
                style={{
                  alignContent: "center",
                  boxSizing: "border-box",
                  maxWidth: width < 1024 ? "100%" : "80%",
                  margin: "0 auto",
                }}
              >
                <h1 style={{ textAlign: "center", marginTop: "-1rem" }}>Welcome to Notifi.xyz</h1>
                <p>
                  Browse messages written to the Ethereum blockchain, or search for an address that you would like to
                  connect with.
                </p>
                <p style={{ marginBottom: "0rem" }}>
                  Follow us on{" "}
                  <a href="https://twitter.com/notifi_xyz" target="_blank">
                    Twitter
                  </a>{" "}
                  to receive on chain messages in your timeline and stay up to date on new features.
                </p>
              </Card>
              <div style={{ width: width < 1024 ? "80%" : "65%", display: "inline-block" }}>
                <Search
                  placeholder={searchPlaceholder}
                  allowClear
                  enterButton="Search"
                  size="large"
                  onSearch={onSearch}
                />
              </div>
            </div>
          )}
          {transactionList[0]
            ? transactionList.map((transaction, index) => (
                <span key={index}>
                  {transaction ? (
                    <Message
                      transaction={transaction}
                      user={user}
                      userFavorites={userFavorites}
                      setUserFavorites={setUserFavorites}
                      apiUrl={apiUrl}
                      apiToken={apiToken}
                      setRoute={setRoute}
                      messageDetailPage={messageDetailPage}
                      provider={provider}
                      logoutOfWeb3Modal={logoutOfWeb3Modal}
                    />
                  ) : (
                    ""
                  )}
                </span>
              ))
            : ""}
          {messageOffset + 20 < totalMessages ? (
            <Button
              key="moreMessagesButton"
              style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
              shape="round"
              size="large"
              onClick={() => setMessageOffset(messageOffset + 20)}
            >
              Load More Messages
            </Button>
          ) : null}
        </div>
      </main>
    </div>
  );

  return display;
}
