import React, { useState, useEffect, useRef } from "react";
import MessageList from "./MessageList";

/*
  ~ What it does? ~

  Fetches the latest on chain messages

  ~ How can I use? ~

  <OnChainMessages
    provider={mainnetProvider}
  />
*/

export default function OnChainMessages({
  provider,
  user,
  userFavorites,
  setUserFavorites,
  setRoute,
  apiUrl,
  apiToken,
  logoutOfWeb3Modal,
  width,
}) {
  const [messages, setMessages] = useState([]);
  const [messageOffset, setMessageOffset] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [sortMethod, setSortMethod] = useState("default");

  async function getMessages(clearMessages) {
    const res = await fetch(
      `${apiUrl}/api/transactions?offset=${messageOffset || 0}&limit=20&sortMethod=${sortMethod || "default"}`,
    ).catch(err => console.log(err));

    if (!res) {
      return [];
    }

    const body = res ? await res.json() : { rows: [] };
    const newMessages = body.rows;

    if (clearMessages) {
      setMessages(newMessages);
    } else {
      setMessages([...messages, ...newMessages]);
    }
    setTotalMessages(body.count);

    return newMessages;
  }

  useEffect(() => {
    setRoute("/");
    getMessages(true);
  }, []);

  useEffect(() => {
    if (messages.length) {
      getMessages();
    }
  }, [messageOffset]);

  useEffect(() => {
    if (messages.length) {
      getMessages(true);
    }
  }, [sortMethod]);

  const display = (
    <MessageList
      transactionList={messages}
      messageOffset={messageOffset}
      setMessageOffset={setMessageOffset}
      totalMessages={totalMessages}
      user={user}
      userFavorites={userFavorites}
      setUserFavorites={setUserFavorites}
      setRoute={setRoute}
      setSortMethod={setSortMethod}
      apiUrl={apiUrl}
      apiToken={apiToken}
      logoutOfWeb3Modal={logoutOfWeb3Modal}
      provider={provider}
      width={width}
    />
  );

  return <div>{display}</div>;
}
