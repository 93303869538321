import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import { inject } from "@vercel/analytics";
import App from "./App";
import "./index.css";

inject();

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
};

ReactDOM.render(
  <ThemeSwitcherProvider themeMap={themes} defaultTheme="dark">
    <App />
  </ThemeSwitcherProvider>,
  document.getElementById("root"),
);
