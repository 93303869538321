import React, { useEffect, useState } from "react";
import { Button, Checkbox, Card, Form, Input } from "antd";

export default function Settings({ apiToken, apiUrl, user, width }) {
  const [email, setEmail] = useState("");
  const [onChainNotificationsEnabled, setOnChainNotificationsEnabled] = useState();
  const [dmNotificationsEnabled, setDmNotificationsEnabled] = useState();
  const [initialState, setInitialState] = useState();
  const [valuesChanged, setValuesChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    setEmail("");
    setOnChainNotificationsEnabled(false);
    setDmNotificationsEnabled(false);
    setInitialState({ email: "", onChainNotificationsEnabled: false, dmNotificationsEnabled: false });

    if (user && apiToken) {
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${apiToken}` },
      };
      await fetch(`${apiUrl}/api/users/${user.id}`, options)
        .then(async res => {
          const body = await res.json();
          const email = body.email;
          const onChainNotificationsEnabled = body.emailNotifications === "on";
          const dmNotificationsEnabled = body.dmNotifications === "on";
          setEmail(email);
          setOnChainNotificationsEnabled(onChainNotificationsEnabled);
          setDmNotificationsEnabled(dmNotificationsEnabled);
          setInitialState({ email, onChainNotificationsEnabled, dmNotificationsEnabled });
        })
        .catch(err => console.log(err));
      setIsLoading(false);
    }
  }, [user, apiToken]);

  useEffect(() => {
    if (initialState && email) {
      if (
        email !== initialState.email ||
        onChainNotificationsEnabled !== initialState.onChainNotificationsEnabled ||
        dmNotificationsEnabled !== initialState.dmNotificationsEnabled
      ) {
        setValuesChanged(true);
      } else {
        setValuesChanged(false);
      }
    }
  }, [initialState, email, onChainNotificationsEnabled, dmNotificationsEnabled]);

  const submit = async () => {
    if (email) {
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${apiToken}` },
        body: JSON.stringify({
          email,
          emailNotifications: onChainNotificationsEnabled ? "on" : "off",
          dmNotifications: dmNotificationsEnabled ? "on" : "off",
        }),
      };
      await fetch(`${apiUrl}/api/users/${user.id}`, options)
        .then(() => setInitialState({ email, onChainNotificationsEnabled, dmNotificationsEnabled }))
        .catch(err => console.log(err));
    }
  };
  return (
    <Card bordered={false} style={{ margin: "auto", width: width > 1023 ? "40%" : "80%" }}>
      <p style={{ textAlign: "left" }}>
        Associate an email address with your account and turn on notifications in order to be notified when your address
        receives an on chain message.
      </p>
      <p style={{ textAlign: "center" }}>More notification options coming soon.</p>
      <Card bordered={true} style={{ marginTop: 16, marginBottom: 16, background: "rgb(40, 40, 40)" }}>
        {isLoading ? (
          ""
        ) : (
          <Form
            style={{
              float: "left",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "1rem",
              width: "100%",
              textAlign: "left",
            }}
            layout="horizontal"
            size="default"
            initialValues={{
              email,
            }}
          >
            {/* <Form.Item label="Preferred Contact Method" name="PreferredContactMethod">
              <Select placeholder="Select preferred contact method">
                <Option value="email">Email</Option>
                <Option value="twitter">Twitter</Option>
                <Option value="">None</Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              label="Email"
              name="email"
              wrapperCol={{
                span: "100%",
                offset: 0,
              }}
              onChange={e => setEmail(e.target.value)}
            >
              <Input />
            </Form.Item>

            <Form.Item name="onChainNotificationsEnabled" style={{ display: "inline-block" }}>
              <Checkbox
                checked={onChainNotificationsEnabled}
                onClick={() => setOnChainNotificationsEnabled(!onChainNotificationsEnabled)}
              >
                Notify me of on chain messages sent to my address
              </Checkbox>
            </Form.Item>
            {/* <Form.Item name="dmNotificationsEnabled" wrapperCol={{ offset: 2 }}>
              <Checkbox
                checked={dmNotificationsEnabled}
                onClick={() => setDmNotificationsEnabled(!dmNotificationsEnabled)}
              >
                Notify me of direct messages sent to my address
              </Checkbox>
            </Form.Item> */}
            <Form.Item wrapperCol={{ offset: 2 }} style={{ float: "right" }}>
              {valuesChanged && email ? (
                <Button type="primary" htmlType="submit" onClick={submit}>
                  Submit
                </Button>
              ) : (
                <Button disabled type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        )}
      </Card>
      <p style={{ textAlign: "left", marginBottom: "0rem" }}>
        Your email address is stored solely for notification purposes and can be removed at any time. It is never
        exposed to other users.
      </p>
    </Card>
  );
}
