import { SendOutlined } from "@ant-design/icons";
import { Button, Modal, Switch } from "antd";
import { ethers, utils } from "ethers";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Transactor } from "../helpers";
import AddressInput from "./AddressInput";
import EtherInput from "./EtherInput";
import BytesStringInput from "./BytesStringInput";
import Account from "./Account";

// This is the notifi.eth wallet
const defaultAddress = "0x940d9b3b0cdb662345c0aa7db96d32eb8abaf359";
const messageFooter = " --- Message sent with https://notifi.xyz";

/*
  ~ What it does? ~

  Displays a wallet where you can specify address and send USD/ETH, with options to
  scan address, to convert between USD and ETH, to see and generate private keys,
  to send, receive and extract the burner wallet

  ~ How can I use? ~

  <SendMessage
    provider={userProvider}
    address={address}
    ensProvider={mainnetProvider}
    price={price}
    color='red'
  />

  ~ Features ~

  - Provide provider={userProvider} to display a wallet
  - Provide address={address} if you want to specify address, otherwise
                                                    your default address will be used
  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth") or you can enter directly ENS name instead of address
  - Provide price={price} of ether and easily convert between USD and ETH
  - Provide color to specify the color of wallet icon
*/
export default function SendMessage(props) {
  const [amount, setAmount] = useState();
  const [toAddress, setToAddress] = useState();
  const [secondaryToAddress, setSecondaryToAddress] = useState();
  const [message, setMessage] = useState();
  const [ensIsLoading, setEnsIsLoading] = useState(false);
  const [dataByteLength, setDataByteLength] = useState(messageFooter.length);
  const [gasLimit, setGasLimit] = useState(21000 + 68 * messageFooter.length);

  const open = props.open;
  const setOpen = props.setOpen;
  const history = useHistory();

  useEffect(() => {
    if (!message) {
      setDataByteLength(messageFooter.length);
      return;
    }
    const rawMessage = utils.toUtf8String(message);
    setDataByteLength(rawMessage.length);
  }, [message]);

  useEffect(() => {
    setGasLimit(21000 + 68 * dataByteLength);
  }, [dataByteLength]);

  useEffect(() => {
    if (open === false) {
      setAmount(0);
      setMessage("");
      setToAddress(defaultAddress);
      setSecondaryToAddress();
      props.setDefaultMessage();
      props.setDefaultAmount();
    } else {
      setToAddress(props.sendMessageRecipient || defaultAddress);
      setAmount(props.defaultAmount || 0);
      if (props.defaultMessage) {
        const defaultMessage = utils.hexlify(utils.toUtf8Bytes(props.defaultMessage));
        const fullValue = encodeMessageValue(defaultMessage);
        setMessage(fullValue);
      } else {
        setMessage("");
      }
    }
  }, [open]);

  function sendOnChainMessage() {
    const tx = Transactor(props.signer || props.provider, props.gasPrice);

    let value;
    try {
      value = ethers.utils.parseEther("" + amount);
    } catch (e) {
      // failed to parseEther, try something else
      value = ethers.utils.parseEther("" + parseFloat(amount).toFixed(8));
    }

    // TODO use tx callback to detect if tx is submitted or canceled
    // only clear state when tax is canceled or fails
    tx({
      to: toAddress,
      value,
      data: message,
      gasLimit: ethers.utils.hexlify(gasLimit),
    });
    setOpen(false);
  }

  async function sendOffChainMessage() {
    const recipientAddresses = [props.user.ethAddress, toAddress];
    if (secondaryToAddress) {
      recipientAddresses.push(secondaryToAddress);
    }
    const rawMessage = utils.toUtf8String(message).replace(messageFooter, "");
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${props.apiToken}` },
      body: JSON.stringify({ recipientAddresses, message: rawMessage, userId: props.user.id }),
    };
    await fetch(`${props.apiUrl}/api/chats`, options)
      .then(async data => {
        const chat = (data ? await data.json() : [{}])[0];
        if (chat && chat.id) {
          history.push(`/directMessages/${chat.id}`);
          props.setOpenDirectMessageId(chat.id);
        } else {
          // TODO notify user of error
        }
      })
      .catch(error => {
        // TODO notify user of error
        console.log(error);
      });
    setOpen(false);
  }

  function isEthAddress(v) {
    if (!v) {
      return true;
    }
    const value = v.trim();
    return (value.startsWith("0x") && value.length === 42) || value.substr(value.length - 4) === ".eth";
  }

  const providerSend = (
    <div
      onClick={() => {
        setOpen(!open);
      }}
    >
      Send Message
    </div>
  );

  const inputStyle = {
    padding: 10,
  };

  const gasUsdEstimate = ((props.gasPrice * gasLimit * props.price) / 1000000000000000000).toFixed(2);

  function encodeMessageValue(value) {
    // TODO add two line breaks
    const encodedValue = utils.hexlify(utils.toUtf8Bytes(messageFooter));
    return props.isOnChainMessage ? value + encodedValue.substring(2, encodedValue.length) : value;
  }

  const display = (
    <div>
      <p style={{ whiteSpace: "pre-line", paddingLeft: 10, padingRight: 10 }}>
        {props.defaultMessage
          ? "Notifi survives off of donations and we really appreciate your support.\n\nThe default donation is 0.01 ETH, but you can change the amount that you would like to donate below."
          : props.isOnChainMessage
          ? `Start a conversation or broadcast a message to the world by writing a message to the Ethereum blockchain.\n\nUse this option if you want to maximize reach, or your recipient is unaware that you're trying to contact them.`
          : "Avoid gas fees by starting a conversation off chain through Notifi's internal messaging system.\n\nThis option is better if your recipient is already a Notifi user, or you don't want to broadcast your conversation to the world."}
      </p>
      {!props.defaultMessage && (
        <div style={{ paddingLeft: 10, paddingBottom: 10, textAlign: "center" }}>
          <div style={{ display: "inline-block", marginRight: "1rem" }}>Off chain</div>
          <Switch
            title={props.isOnChainMessage ? "Send message off chain" : "Send message on chain"}
            checked={props.isOnChainMessage}
            onChange={() => props.setIsOnChainMessage(!props.isOnChainMessage)}
          />
          <div style={{ display: "inline-block", marginLeft: "1rem" }}>On chain</div>
        </div>
      )}
      {!props.defaultAmount && (
        <div style={inputStyle}>
          <BytesStringInput
            autoFocus
            value={message}
            placeholder="Enter message..."
            onChange={value => {
              if (value && value !== "0x") {
                const fullValue = encodeMessageValue(value);
                setMessage(fullValue);
              } else {
                setMessage();
              }
            }}
          />
        </div>
      )}
      {!props.defaultMessage && (
        <div style={inputStyle}>
          <AddressInput
            ensProvider={props.ensProvider}
            placeholder={
              props.isOnChainMessage
                ? "recipient address (optional) - default is notifi.eth"
                : "recipient address (required)"
            }
            address={toAddress}
            setEnsIsLoading={setEnsIsLoading}
            onChange={address => {
              if (address) {
                setToAddress(address);
              } else if (props.isOnChainMessage) {
                setToAddress(defaultAddress);
              }
            }}
          />
        </div>
      )}
      {!props.isOnChainMessage && (
        <div style={inputStyle}>
          <AddressInput
            ensProvider={props.ensProvider}
            placeholder="secondary recipient address (optional)"
            address={secondaryToAddress}
            setEnsIsLoading={setEnsIsLoading}
            onChange={address => setSecondaryToAddress(address)}
          />
        </div>
      )}
      {props.isOnChainMessage && (
        <div style={inputStyle}>
          <EtherInput
            autoFocus={props.defaultMessage ? true : false}
            price={props.price}
            value={amount}
            placeholder={
              props.defaultAmount ? props.defaultAmount : "tip (optional) - default is 0, 100% goes to recipient"
            }
            onChange={value => {
              setAmount(value);
            }}
          />
          {!props.defaultAmount && (
            <div style={{ float: "right", paddingTop: 5 }}>{`Current gas estimate: $${gasUsdEstimate}`}</div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <span>
      {providerSend}
      <Modal
        visible={open}
        title={
          props.defaultAmount
            ? "Donate to Notifi.xyz"
            : props.isOnChainMessage
            ? "Send message on chain"
            : "Send message off chain"
        }
        onOk={() => {
          setOpen(!open);
        }}
        onCancel={() => {
          setOpen(!open);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={
              !props.user ||
              (!props.defaultMessage &&
                (!message ||
                  !toAddress ||
                  ensIsLoading ||
                  !isEthAddress(toAddress) ||
                  !isEthAddress(secondaryToAddress)))
            }
            loading={false}
            onClick={() => {
              props.isOnChainMessage ? sendOnChainMessage() : sendOffChainMessage();
            }}
          >
            <SendOutlined /> Send
          </Button>,
        ]}
      >
        {props.user ? (
          display
        ) : (
          <Account
            setOpen={setOpen}
            mainnetProvider={props.provider}
            web3Modal={props.web3Modal}
            loadWeb3Modal={props.loadWeb3Modal}
          />
        )}
      </Modal>
    </span>
  );
}
