import WalletConnectProvider from "@walletconnect/web3-provider";
//import Torus from "@toruslabs/torus-embed"
import WalletLink from "walletlink";
import { Alert, Button, Menu, Popover } from "antd";
import "antd/dist/antd.css";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import MetaTags from "react-meta-tags";
import Web3Modal from "web3modal";
import "./App.css";
import { Account, Header, Footer, OnChainMessages, SendMessage } from "./components";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import { useUserProviderAndSigner, useGasPrice } from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import { DirectMessages, FavoritedMessages, Profile, SingleMessage, Settings } from "./views";

import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import Authereum from "authereum";

const { SubMenu } = Menu;
const { ethers } = require("ethers");
/*
    TODO
    You should get your own Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)
*/
const apiUrl = "https://api.notifi.xyz";
// const apiUrl = "http://localhost:8080";

/// 📡 What chain are your contracts deployed to?
const targetNetwork = NETWORKS.mainnet; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
const NETWORKCHECK = true;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");
// const mainnetProvider = getDefaultProvider("mainnet", { infura: INFURA_ID, etherscan: ETHERSCAN_KEY, quorum: 1 });
// const mainnetProvider = new InfuraProvider("mainnet",INFURA_ID);
//
// attempt to connect to our own scaffold eth rpc and if that fails fall back to infura...
// Using StaticJsonRpcProvider as the chainId won't change see https://github.com/ethers-io/ethers.js/issues/901
const scaffoldEthProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://rpc.scaffoldeth.io:48544")
  : null;
const poktMainnetProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider(
      "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
    )
  : null;
const mainnetInfura = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)
  : null;
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_ID
// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

// Coinbase walletLink init
const walletLink = new WalletLink({
  appName: "coinbase",
});

// WalletLink provider
const walletLinkProvider = walletLink.makeWeb3Provider(`https://mainnet.infura.io/v3/${INFURA_ID}`, 1);

// Portis ID: 6255fb2b-58c8-433b-a2c9-62098c05ddc9
/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "dark", // optional. Change to "dark" for a dark theme.
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        bridge: "https://polygon.bridge.walletconnect.org",
        infuraId: INFURA_ID,
        rpc: {
          1: `https://mainnet.infura.io/v3/${INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
          42: `https://kovan.infura.io/v3/${INFURA_ID}`,
          100: "https://dai.poa.network", // xDai
        },
      },
    },
    portis: {
      display: {
        logo: "https://user-images.githubusercontent.com/9419140/128913641-d025bc0c-e059-42de-a57b-422f196867ce.png",
        name: "Portis",
        description: "Connect to Portis App",
      },
      package: Portis,
      options: {
        id: "6255fb2b-58c8-433b-a2c9-62098c05ddc9",
      },
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: "pk_live_5A7C91B2FC585A17", // required
      },
    },
    // torus: {
    //   package: Torus,
    //   options: {
    //     networkParams: {
    //       host: "https://localhost:8545", // optional
    //       chainId: 1337, // optional
    //       networkId: 1337 // optional
    //     },
    //     config: {
    //       buildEnv: "development" // optional
    //     },
    //   },
    // },
    "custom-walletlink": {
      display: {
        logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
        name: "Coinbase",
        description: "Connect to Coinbase Wallet (not Coinbase App)",
      },
      package: walletLinkProvider,
      connector: async (provider, _options) => {
        await provider.enable();
        return provider;
      },
    },
    authereum: {
      package: Authereum, // required
    },
  },
});

function App(props) {
  const mainnetProvider =
    poktMainnetProvider && poktMainnetProvider._isProvider
      ? poktMainnetProvider
      : scaffoldEthProvider && scaffoldEthProvider._network
      ? scaffoldEthProvider
      : mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();
  const [injectedProviderAddress, setInjectedProviderAddress] = useState();
  const [address, setAddress] = useState();
  const [user, setUser] = useState();
  const [userAndUserSigner, setUserAndUserSigner] = useState();
  const [userFavorites, setUserFavorites] = useState();
  const [showUserPages, setShowUserPages] = useState(false);
  const [signedMessage, setSignedMessage] = useState();
  const [apiToken, setApiToken] = useState();
  const [sendMessageHover, setSendMessageHover] = useState(false);
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [sendMessageRecipient, setSendMessageRecipient] = useState();
  const [defaultMessage, setDefaultMessage] = useState();
  const [defaultAmount, setDefaultAmount] = useState();
  const [isOnChainMessage, setIsOnChainMessage] = useState(true);
  const [openDirectMessageId, setOpenDirectMessageId] = useState();
  const [chatList, setChatList] = useState([]);
  const [width, setWindowWidth] = useState(0);

  const logoutOfWeb3Modal = async alertMessage => {
    await web3Modal.clearCachedProvider();
    window.localStorage.removeItem("apiToken");
    window.localStorage.removeItem("signedMessage");
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
      if (alertMessage && typeof alertMessage === "string") {
        alert(alertMessage);
      }
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, null);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(async () => {
    const selectedAddress =
      injectedProvider &&
      injectedProvider.provider &&
      (injectedProvider.provider.selectedAddress || injectedProvider.provider.accounts[0]);
    if (selectedAddress) {
      setInjectedProviderAddress(selectedAddress.toLowerCase());
    }
  }, [injectedProvider]);

  useEffect(async () => {
    if (injectedProviderAddress) {
      const userData = await findOrCreateUser(injectedProviderAddress);
      setUser(userData);

      const cachedSignedMessage = window.localStorage.getItem("signedMessage");
      if (cachedSignedMessage) {
        setShowUserPages(true);
      }
    }
  }, [injectedProviderAddress]);

  useEffect(() => {
    async function getAddress() {
      const cachedSignedMessage = window.localStorage.getItem("signedMessage");
      if (user && userSigner) {
        const rawMessage = `Log in to Notifi with nonce: ${user.nonce}`;
        const rawSignedMessage =
          cachedSignedMessage ||
          (await userSigner.signMessage(rawMessage).catch(_error => {
            logoutOfWeb3Modal();
          }));
        setSignedMessage(rawSignedMessage);
        if (!cachedSignedMessage) window.localStorage.setItem("signedMessage", rawSignedMessage);
        setShowUserPages(true);
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress && newAddress.toLowerCase());
      }
    }
    getAddress();
  }, [userAndUserSigner]);

  useEffect(() => {
    if (user && userSigner) {
      setUserAndUserSigner(true);
    }
  }, [user, userSigner]);

  useEffect(async () => {
    const cachedApiToken = window.localStorage.getItem("apiToken");
    if (signedMessage && injectedProviderAddress) {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ethAddress: injectedProviderAddress,
          signedMessage,
        }),
      };
      let newApiToken = cachedApiToken;
      if (!newApiToken) {
        const res = await fetch(`${apiUrl}/api/users/auth`, options).catch(error => {
          console.log(error);
          return {};
        });
        const body = await res.json();
        newApiToken = body.token;
      }
      window.localStorage.setItem("apiToken", newApiToken);
      setApiToken(newApiToken);

      const userOptions = {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${newApiToken}` },
      };
      fetch(`${apiUrl}/api/favoriteTransactions?userId=${user.id}`, userOptions)
        .then(async userFavRes => {
          const userFavBody = userFavRes ? await userFavRes.json() : { rows: [] };
          if (userFavBody.rows) {
            setUserFavorites(userFavBody.rows);
          }
        })
        .catch(error => {
          console.log(error);
          logoutOfWeb3Modal();
        });

      fetch(`${apiUrl}/api/users/${user.id}`, userOptions)
        .then(async userRes => {
          const body = await userRes.json();
          if (userRes.status === 401) {
            logoutOfWeb3Modal();
            return;
          }
          setUser(body);
        })
        .catch(error => {
          console.log(error);
          logoutOfWeb3Modal();
        });
    }
  }, [signedMessage]);

  useEffect(() => {
    if (!sendMessageModalOpen) {
      setIsOnChainMessage(true);
      setSendMessageRecipient(undefined);
    }
  }, [sendMessageModalOpen]);

  useEffect(async () => {
    if (!user || !apiToken || !apiUrl) {
      return;
    }
    fetchUserChats("visible");
    // TODO open websocket instead of interval
    setInterval(() => {
      fetchUserChats("visible");
    }, 30000);
  }, [user]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  // const tx = Transactor(userSigner, gasPrice);

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (DEBUG && mainnetProvider && address && selectedChainId) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
    }
  }, [mainnetProvider, address, selectedChainId]);

  let networkDisplay = "";
  if (NETWORKCHECK && localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network"
            description={
              <div>
                You have <b>{networkSelected && networkSelected.name}</b> selected and you need to be on{" "}
                <Button
                  onClick={async () => {
                    const ethereum = window.ethereum;
                    const data = [
                      {
                        chainId: "0x" + targetNetwork.chainId.toString(16),
                        chainName: targetNetwork.name,
                        nativeCurrency: targetNetwork.nativeCurrency,
                        rpcUrls: [targetNetwork.rpcUrl],
                        blockExplorerUrls: [targetNetwork.blockExplorer],
                      },
                    ];
                    console.log("data", data);

                    let switchTx;
                    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
                    try {
                      switchTx = await ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: data[0].chainId }],
                      });
                    } catch (switchError) {
                      // not checking specific error code, because maybe we're not using MetaMask
                      try {
                        switchTx = await ethereum.request({
                          method: "wallet_addEthereumChain",
                          params: data,
                        });
                      } catch (addError) {
                        // handle "add" error
                      }
                    }

                    if (switchTx) {
                      console.log(switchTx);
                    }
                  }}
                >
                  <b>{networkLocal && networkLocal.name}</b>
                </Button>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = (
      <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
        {targetNetwork.name}
      </div>
    );
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    if (!injectedProvider) {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    }

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [injectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  function fetchUserChats(chatMode) {
    const options = {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${apiToken}` },
    };
    let url = `${apiUrl}/api/chats?userIds[]=${user.id}`;
    if (chatMode === "hidden") {
      url += "&hidden=true";
    }
    // TODO implement pagination
    fetch(url, options)
      .then(async res => {
        const body = res ? await res.json() : { rows: [] };
        if (body.rows && body.rows.length) {
          const chats = body.rows.map(chat => {
            chat.users = chat.users.map(userId => body.chatUsers[userId]);
            return chat;
          });
          const chatsWithEns = chats.map(chat => {
            chat.users = chat.users.map(chatUser => {
              const ensData = chatUser.ensData;
              if (ensData) {
                chatUser.ensName = ensData.name;
                chatUser.avatar = ensData.avatar;
              }
              return chatUser;
            });
            return chat;
          });
          setChatList(chatsWithEns);
        } else {
          setChatList([]);
        }
      })
      .catch(error => {
        console.log(error);
        // logoutOfWeb3Modal();
      });
  }

  async function findOrCreateUser(ethAddress) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ethAddress }),
    };
    return fetch(`${apiUrl}/api/users`, options)
      .then(async res => {
        const body = res ? await res.json() : [{}];
        // response is an array including the user data and a boolean signaling if the user is new or not
        const userData = body[0];
        return userData;
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <div className="App">
      <Header width={width} />
      <MetaTags>
        <meta name="description" content="An Ethereum messaging service." />{" "}
      </MetaTags>
      <BrowserRouter>
        <Menu
          style={{ textAlign: "center" }}
          selectedKeys={window.location.pathname.includes(route) ? [route] : ""}
          mode="horizontal"
        >
          <Menu.Item key="/">
            <Link
              onClick={() => {
                setRoute("/");
              }}
              to="/"
            >
              Home
            </Link>
          </Menu.Item>
          {showUserPages && width > 1023 && (
            <Menu.Item key="/profile">
              <Link
                onClick={() => {
                  setRoute("/profile");
                }}
                to="/profile"
              >
                My Profile
              </Link>
            </Menu.Item>
          )}
          {showUserPages && (
            <Menu.Item
              key="/directMessages"
              style={{
                backgroundColor:
                  !window.location.pathname.includes("/directMessages") && chatList.some(chat => chat.unreadMessageId)
                    ? "#cc9878"
                    : null,
              }}
            >
              <Link
                onClick={() => {
                  setRoute("/directMessages");
                }}
                to="/directMessages"
              >
                Direct Messages
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/sendMessage">
            <div style={{ display: "inline-block" }}>
              <SendMessage
                apiUrl={apiUrl}
                apiToken={apiToken}
                user={user}
                provider={injectedProvider}
                ensProvider={mainnetProvider}
                price={price}
                gasPrice={gasPrice}
                open={sendMessageModalOpen}
                setOpen={setSendMessageModalOpen}
                isOnChainMessage={isOnChainMessage}
                setIsOnChainMessage={setIsOnChainMessage}
                setOpenDirectMessageId={setOpenDirectMessageId}
                sendMessageRecipient={sendMessageRecipient}
                defaultMessage={defaultMessage}
                setDefaultMessage={setDefaultMessage}
                defaultAmount={defaultAmount}
                setDefaultAmount={setDefaultAmount}
                web3Modal={web3Modal}
                loadWeb3Modal={loadWeb3Modal}
              />
            </div>
          </Menu.Item>
          {showUserPages && (
            <SubMenu key="SubMenu" title="User Pages">
              {width < 1024 && (
                <Menu.Item key="/profile">
                  <Link
                    onClick={() => {
                      setRoute("/profile");
                    }}
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="/favoritedMessages">
                <Link
                  onClick={() => {
                    setRoute("/favoritedMessages");
                  }}
                  to="/favoritedMessages"
                >
                  Favorite Messages
                </Link>
              </Menu.Item>
              <Menu.Item key="/settings">
                <Link
                  onClick={() => {
                    setRoute("/settings");
                  }}
                  to="/settings"
                >
                  Settings
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
        <Switch>
          <Route exact path={["/", "/onChainMessages"]}>
            <OnChainMessages
              provider={mainnetProvider}
              user={user}
              userFavorites={userFavorites}
              setUserFavorites={setUserFavorites}
              setRoute={setRoute}
              apiUrl={apiUrl}
              apiToken={apiToken}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              width={width}
            />
          </Route>
          <Route path="/profile">
            <Profile
              apiUrl={apiUrl}
              findOrCreateUser={findOrCreateUser}
              provider={mainnetProvider}
              setIsOnChainMessage={setIsOnChainMessage}
              setSendMessageModalOpen={setSendMessageModalOpen}
              setSendMessageRecipient={setSendMessageRecipient}
              user={user}
              userFavorites={userFavorites}
              width={width}
            />
          </Route>
          <Route path="/favoritedMessages">
            <FavoritedMessages
              provider={mainnetProvider}
              user={user}
              userFavorites={userFavorites}
              setUserFavorites={setUserFavorites}
              setRoute={setRoute}
              apiUrl={apiUrl}
              apiToken={apiToken}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />
          </Route>
          <Route path="/settings">
            <Settings apiToken={apiToken} apiUrl={apiUrl} user={user} width={width} />
          </Route>
          <Route path="/directMessages">
            <DirectMessages
              apiToken={apiToken}
              apiUrl={apiUrl}
              chatList={chatList}
              setChatList={setChatList}
              fetchUserChats={fetchUserChats}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              user={user}
              setSendMessageModalOpen={setSendMessageModalOpen}
              setIsOnChainMessage={setIsOnChainMessage}
              openDirectMessageId={openDirectMessageId}
              setOpenDirectMessageId={setOpenDirectMessageId}
              width={width}
            />
          </Route>
          <Route path="/messages/:id">
            <SingleMessage
              user={user}
              userFavorites={userFavorites}
              setUserFavorites={setUserFavorites}
              setRoute={setRoute}
              apiUrl={apiUrl}
              apiToken={apiToken}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />
          </Route>
        </Switch>
        <Footer
          setIsOnChainMessage={setIsOnChainMessage}
          setSendMessageRecipient={setSendMessageRecipient}
          setDefaultMessage={setDefaultMessage}
          setDefaultAmount={setDefaultAmount}
          setSendMessageModalOpen={setSendMessageModalOpen}
          user={user}
        />
      </BrowserRouter>

      {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
      <div style={{ position: "fixed", textAlign: "right", right: 0, top: 0, padding: 10, zIndex: 2 }}>
        <Account
          address={address}
          mainnetProvider={mainnetProvider}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          blockExplorer={blockExplorer}
          signedMessage={signedMessage}
          user={user}
        />
      </div>
    </div>
  );
}

export default App;
