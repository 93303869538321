import React, { useState, useEffect, useRef } from "react";
import { MessageList } from "../components";
import getEnsNamesForMessages from "../helpers/ens";

/*
  ~ What it does? ~

  Fetches the favorited on chain messages for the given user.

  ~ How can I use? ~

  <FavoritedMessages
    provider={mainnetProvider}
    user={user}
    userFavorites={userFavorites}
    setUserFavorites={setUserFavorites}
    apiUrl={apiUrl}
  />
*/

export default function FavoritedMessages({
  provider,
  user,
  userFavorites,
  setUserFavorites,
  setRoute,
  apiUrl,
  apiToken,
  logoutOfWeb3Modal,
}) {
  const [messages, setMessages] = useState([]);
  const [messageOffset, setMessageOffset] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const componentMounted = useRef(true);

  useEffect(() => {
    setRoute("/favoritedMessages");
  }, []);

  useEffect(() => {
    const userFavs = userFavorites || [];
    if (userFavs) {
      const favoriteMessages = userFavs.map(fav => fav.transaction).filter(fav => fav);
      setMessages(favoriteMessages);
      setTotalMessages(favoriteMessages.count);
      return getEnsNamesForMessages(provider, favoriteMessages).then(msgsWithEns => {
        if (componentMounted.current) {
          setMessages(msgsWithEns);
        }
      });
    }
  }, [userFavorites]);

  const display = (
    <MessageList
      transactionList={messages}
      messageOffset={messageOffset}
      setMessageOffset={setMessageOffset}
      totalMessages={totalMessages}
      user={user}
      userFavorites={userFavorites}
      setUserFavorites={setUserFavorites}
      setRoute={setRoute}
      apiUrl={apiUrl}
      apiToken={apiToken}
      logoutOfWeb3Modal={logoutOfWeb3Modal}
    />
  );

  return <div>{display}</div>;
}
