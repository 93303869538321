import { Col, Layout, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer({
  setIsOnChainMessage,
  setSendMessageRecipient,
  setDefaultMessage,
  setDefaultAmount,
  setSendMessageModalOpen,
  user,
}) {
  const openSendModal = () => {
    if (!user) {
      setSendMessageModalOpen(true);
      return;
    }
    const userAddress = (user.ensData && user.ensData.name) || user.ethAddress;
    setIsOnChainMessage(true);
    setSendMessageRecipient("notifi.eth");
    setDefaultMessage(`${userAddress} generously helped fund Notifi!`);
    setDefaultAmount(0.01);
    setSendMessageModalOpen(true);
  };

  return (
    <Layout.Footer
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxHeight: "4rem",
        borderTop: "1px solid #424242",
      }}
    >
      <div style={{ margin: "auto", width: "50%" }}>
        <Row style={{ rowGap: "1rem" }}>
          <Col xs={6} sm={6} md={6} lg={6}>
            {/* TODO create support@notifi.xyz email address */}
            <a href={`mailto:flippen6485@gmail.com`} target="_blank">
              Contact
            </a>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <a href="https://twitter.com/notifi_xyz" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <a onClick={openSendModal}>Donate</a>
          </Col>
        </Row>
      </div>
    </Layout.Footer>
  );
}
