import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { MessageList } from "../components";

/*
  ~ What it does? ~

  Displays a single message by id

  ~ How can I use? ~

  <SingleMessage
    userSigner={userSigner}
    tx={tx}
    price={price}
    apiUrl={apiUrl}
  />
*/

export default function SingleMessage({
  user,
  userFavorites,
  setUserFavorites,
  setRoute,
  apiUrl,
  apiToken,
  logoutOfWeb3Modal,
}) {
  const [transaction, setTransaction] = useState();

  useEffect(async () => {
    const id = window.location.pathname.split("/")[2];
    const url = `${apiUrl}/api/transactions/${id}`;
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(url, options).catch(error => {
      console.log(error);
      return {};
    });
    const body = await res.json();
    setTransaction(body);
  }, [user]);

  const display = transaction ? (
    <div>
      <MetaTags>
        {transaction.message ? (
          <meta
            name="description"
            content={
              transaction.message.length > 180 ? transaction.message.substring(0, 180) + "..." : transaction.message
            }
          />
        ) : (
          ""
        )}
      </MetaTags>
      <MessageList
        transactionList={[transaction]}
        user={user}
        userFavorites={userFavorites || []}
        setUserFavorites={setUserFavorites}
        apiUrl={apiUrl}
        apiToken={apiToken || window.localStorage.getItem("apiToken")}
        setRoute={setRoute}
        messageDetailPage={true}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
      />
    </div>
  ) : (
    ""
  );

  return display;
}
