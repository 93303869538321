import { flatten, uniq } from "ramda";

async function resolveEnsData(resolver) {
  const ensData = {};
  if (resolver) {
    const [twitter, email, avatar] = await Promise.all([
      resolver.getText("com.twitter"),
      resolver.getText("email"),
      resolver.getText("avatar"),
    ]);

    ensData.email = email;
    ensData.twitter = twitter;
    ensData.avatar = avatar;
  }
  return ensData;
}

async function getAvatar(provider, ens) {
  return provider.getAvatar(ens);
}

async function getEnsData(provider, ens, address) {
  let ensData = {};
  if (!ens && !address) {
    return { address, ens, ensData };
  }
  try {
    if (!ens) {
      ens = await provider.lookupAddress(address);
    }
    if (!address) {
      address = await provider.resolveName(ens);
    }
    const resolver = await provider.getResolver(ens);
    if (resolver) {
      ensData = await resolveEnsData(resolver);
    }
    return { address, ens, ensData };
  } catch (error) {
    console.log(error);
    return { address, ens, ensData };
  }
}

async function getEnsNames(provider, addresses) {
  return (await Promise.all(addresses.map(address => getEnsData(provider, null, address)))).reduce(
    (acc, { address, ens, ensData }) => {
      acc[address] = { ens, ensData };
      return acc;
    },
    {},
  );
}

function addEnsDataToTransaction(transaction, ensNameMap) {
  if (ensNameMap[transaction.from]) {
    transaction.fromEns = ensNameMap[transaction.from].ens;
    transaction.fromEnsData = ensNameMap[transaction.from].ensData;
  }
  if (ensNameMap[transaction.to]) {
    transaction.toEns = ensNameMap[transaction.to].ens;
    transaction.toEnsData = ensNameMap[transaction.to].ensData;
  }
  return transaction;
}

export default async function getEnsNamesForMessages(provider, msgs) {
  const addresses = uniq(flatten(msgs.filter(msg => !msg.fromEnsData).map(msg => [msg.to, msg.from])));
  const ensNameMap = await getEnsNames(provider, addresses);

  return msgs.map(msg => addEnsDataToTransaction(msg, ensNameMap));
}

export { getEnsData, getEnsNames, getAvatar };
