import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Card, Form, Input, Layout, List, Menu } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { io } from "socket.io-client";
import Blockies from "react-blockies";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { formatShortEthAddress } from "../helpers/utils.js";

const { Content } = Layout;

let socket;

export default function DirectMessages({
  apiToken,
  apiUrl,
  chatList,
  setChatList,
  fetchUserChats,
  logoutOfWeb3Modal,
  setIsOnChainMessage,
  setSendMessageModalOpen,
  user,
  openDirectMessageId,
  setOpenDirectMessageId,
  width,
}) {
  const [currentChat, setCurrentChat] = useState();
  const [initialOpenDirectMessageId, setInitialOpenDirectMessageId] = useState();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState();
  const [messageOffset, setMessageOffset] = useState(0);
  const [moreMessages, setMoreMessages] = useState(true);
  const [chatListMode, setChatListMode] = useState("visible");
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [form] = Form.useForm();

  const messageLimit = 20;
  const history = useHistory();

  useEffect(() => {
    const defaultChatIdStr = window.location.pathname.split("/")[2];
    if (defaultChatIdStr && !openDirectMessageId) {
      setInitialOpenDirectMessageId(parseInt(defaultChatIdStr, 10));
      setOpenDirectMessageId(parseInt(defaultChatIdStr, 10));
    } else if (openDirectMessageId) {
      setInitialOpenDirectMessageId(openDirectMessageId);
    }
  }, []);

  useEffect(async () => {
    if (!(user || {}).createdAt || !apiToken || !apiUrl || !chatListMode) {
      return;
    }
    fetchUserChats(chatListMode);
    setInitialFetchComplete(true);
  }, [user, apiToken, apiUrl]);

  useEffect(async () => {
    if (initialFetchComplete) {
      fetchUserChats(chatListMode);
    }
  }, [chatListMode]);

  useEffect(() => {
    if (message) {
      setMessages(messages => [message, ...messages]);
      setMessageOffset(messageOffset + 1);
    }
  }, [message]);

  useEffect(() => {
    if (chatList.length > 1 && openDirectMessageId) {
      if (currentChat && currentChat.id === openDirectMessageId) {
        return;
      }
      const newCurrentChat = chatList.find(chat => chat.id === openDirectMessageId);
      if (!newCurrentChat) {
        setChatListMode(chatListMode === "visible" ? "hidden" : "visible");
        return;
      }
      setCurrentChat(newCurrentChat);
    } else if ((chatList.length, "chatList.length")) {
      setCurrentChat(chatList[0]);
    }
  }, [chatList]);

  useEffect(() => {
    if (!currentChat) {
      if (chatList) {
        setCurrentChat(chatList[0]);
      } else {
        fetchUserChats(chatListMode);
      }
      return;
    }
    if (apiUrl.includes("localhost")) {
      socket = io("ws://localhost:8080");
    } else {
      socket = io("wss://api.notifi.xyz");
    }
    socket.on("connect", () => {
      const ensData = user.ensData || {};
      console.log(`${socket.id} connected to chat ${currentChat.id}`);
      socket.emit("joinChat", {
        userId: user.id,
        chatId: currentChat.id,
        token: apiToken,
        username: ensData.name || user.ethAddress,
        avatar: ensData.avatar,
      });
      fetchChatMessages();
      history.push(`/directMessages/${currentChat.id}`);
      setOpenDirectMessageId(currentChat.id);
      socket.emit("readMessage", { token: apiToken, chatId: currentChat.id });
    });

    socket.on("message", message => {
      setMessage(message);
      const currentChatIndex = chatList.find(chat => chat.id === currentChat.id);
      if (currentChatIndex !== 0) {
        // move currentChat to the front of the list
        const newChatList = chatList.filter(chat => chat.id !== currentChat.id);
        newChatList.unshift(currentChat);
        setChatList(newChatList);
      }
    });

    socket.on("messages", resp => {
      const { messages: existingMessages, count: remainingMessages } = resp;
      setMessages(messages => [...messages, ...existingMessages]);
      if (remainingMessages < 1) {
        setMoreMessages(false);
      }
    });

    socket.on("readMessageSuccess", isSuccess => {
      if (isSuccess) {
        fetchUserChats(chatListMode);
      }
    });

    socket.on("error", errorMessage => {
      if (errorMessage === "Invalid token") {
        logoutOfWeb3Modal();
      }
      console.error(errorMessage);
    });

    socket.on("disconnect", () => {
      console.log(`socket disconnected from chat ${currentChat.id}`);
      setMessage();
      setMessages([]);
      setCurrentChat();
      setMessageOffset(0);
      setMoreMessages(true);
      socket.emit("leaveChat", { token: apiToken });
    });

    return () => {
      socket.disconnect();
    };
  }, [currentChat]);

  function fetchChatMessages() {
    if (moreMessages) {
      socket.emit("getMessages", { limit: messageLimit, offset: messageOffset, token: apiToken });
      setMessageOffset(messageOffset + messageLimit);
    }
  }

  function createNewChat() {
    setIsOnChainMessage(false);
    setSendMessageModalOpen(true);
  }

  function connectChat(chat) {
    if ((chat && chat.id) === (currentChat && currentChat.id)) {
      return;
    }
    if (currentChat) {
      socket.disconnect();
    }
    setCurrentChat(chat);
  }

  const sendMessage = values => {
    socket.emit("sendMessage", { token: apiToken, text: values.message });
    socket.emit("readMessage", { token: apiToken, chatId: currentChat.id });
    form.resetFields();
  };

  function getMessageTimestamp(createdAt) {
    return `(${moment(createdAt).fromNow()})`;
  }

  const hideChat = chatId => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${apiToken}` },
      body: JSON.stringify({
        chatId,
        action: chatListMode === "hidden" ? "unhide" : "hide",
      }),
    };
    // TODO implement pagination
    fetch(`${apiUrl}/api/chats/hide`, options)
      .then(() => {
        const newChatList = chatList.filter(chat => chat.id !== chatId);
        setChatList(newChatList);
      })
      .catch(error => {
        console.log(error);
        // logoutOfWeb3Modal();
      });
  };

  function messageCard(index, avatar, ethAddress, username, el, align) {
    let message = el.message;
    let backgroundColor = align === "right" ? "#78a2cc" : "#cc9878";
    // TODO find a better way to detect on chain message
    if (el.transactionId) {
      message = el.message.replace(" --- Message sent with https://notifi.xyz", "");
      backgroundColor = "#78cca2";
    }
    return (
      <div style={{ float: align, padding: "0.5rem" }} key={index}>
        <Card
          bordered={true}
          style={{
            width: "90%",
            float: align,
            background: backgroundColor,
            borderRadius: "25px",
          }}
          bodyStyle={{ padding: "1rem" }}
        >
          <div style={{ veritcalAlign: "middle" }}>
            <Link to={`/profile/${ethAddress}`}>
              <div style={{ float: "left", display: "inline-block" }}>
                {avatar ? (
                  <img style={{ width: "48px", height: "48px" }} src={avatar} />
                ) : (
                  <Blockies size={8} scale={6} seed={ethAddress.toLowerCase()} />
                )}
              </div>
            </Link>
            <div style={{ float: "left", fontWeight: "bold", marginLeft: "1rem" }}>{` ${username}`}</div>
            <div style={{ float: "right", fontStyle: "italic", display: "inline-block" }}>
              {getMessageTimestamp(el.createdAt)}
            </div>
            {el.transaction && (
              <div>
                <br />
                <div style={{ float: "right" }}>{`Tip: ${el.transaction.value} ETH`}</div>
                <br />
                <a href={`https://etherscan.io/tx/${el.transaction.hash}`} target="_blank" style={{ float: "right" }}>
                  Etherscan
                </a>
              </div>
            )}
          </div>
          <br style={{ clear: "both" }} />
          <p
            style={{
              paddingLeft: "4rem",
              marginBottom: "0rem",
              textAlign: "left",
            }}
          >
            {message}
          </p>
        </Card>
      </div>
    );
  }

  const formatChatUsers = chat => {
    if (chat && chat.users) {
      return chat.users
        .filter(chatUser => chatUser.ethAddress !== user.ethAddress)
        .map(chatUser => chatUser.ensName || formatShortEthAddress(chatUser.ethAddress))
        .join(", ");
    }
  };

  return (
    <div style={{ width: width > 1023 ? "80%" : "100%", margin: "auto", height: "82vh" }}>
      <Card
        bordered={true}
        style={{ marginTop: 16, marginBottom: 16 }}
        bodyStyle={{
          display: "grid",
          gridGap: "1rem",
          height: "100%",
        }}
      >
        <Menu
          style={{
            textAlign: "center",
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
          selectedKeys={chatListMode}
          mode={width > 1023 ? "horizontal" : "inline"}
        >
          <Menu.Item
            key="visible"
            onClick={() => {
              if (chatListMode !== "visible") {
                setChatListMode("visible");
              }
            }}
          >
            Messages
          </Menu.Item>
          <Menu.Item
            key="hidden"
            onClick={() => {
              if (chatListMode !== "hidden") {
                setChatListMode("hidden");
              }
            }}
          >
            Hidden
          </Menu.Item>
        </Menu>
        <div
          style={{
            gridColumnStart: width > 1023 ? 1 : 2,
            gridColumnEnd: width > 1023 ? 1 : 8,
            gridRowStart: width > 1023 ? 2 : 1,
            gridRowEnd: width > 1023 ? 3 : 2,
            // maxHeight: width > 1023 ? "100%" : "14rem",
            overflow: "auto",
          }}
        >
          <List
            itemLayout="horizontal"
            style={{ maxHeight: width > 1023 ? "100%" : "8rem", overflow: "scroll" }}
            dataSource={[{ id: "createNew", type: "createNew" }].concat(chatList)}
            renderItem={chat => (
              <List.Item
                style={{
                  borderWidth: chat.id === (currentChat && currentChat.id) ? "0.3rem" : "0.1rem",
                  padding: "0.5rem",
                  paddingLeft: chat.id === (currentChat && currentChat.id) ? "1rem" : "0rem",
                  backgroundColor:
                    chat.id !== (currentChat && currentChat.id) && chat.unreadMessageId
                      ? "#cc9878"
                      : chat.id === (currentChat && currentChat.id)
                      ? "rgb(40, 40, 40)"
                      : "",
                }}
              >
                <List.Item.Meta
                  style={{ cursor: "pointer" }}
                  onClick={() => (chat.type === "createNew" ? createNewChat() : connectChat(chat))}
                  avatar={
                    chat.type === "createNew" ? null : (
                      <div style={{ display: "flex" }}>
                        {chat.users
                          .filter(chatUser => chatUser.ethAddress !== user.ethAddress)
                          .map((chatUser, index) => {
                            const marginLeft = index > 0 ? index - 17 : 0;
                            const marginTop = index > 0 ? index + 5 : 0;
                            return chatUser.avatar ? (
                              <div
                                key={chatUser.id}
                                style={{
                                  display: "flex",
                                  width: "32px",
                                  height: "32px",
                                  marginLeft: `${marginLeft}px`,
                                  marginTop: `${marginTop}px`,
                                }}
                              >
                                <img src={chatUser.avatar} />
                              </div>
                            ) : (
                              <div
                                key={chatUser.id}
                                style={{ display: "flex", marginLeft: `${marginLeft}px`, marginTop: `${marginTop}px` }}
                              >
                                <Blockies seed={chatUser.ethAddress.toLowerCase()} />
                              </div>
                            );
                          })}
                      </div>
                    )
                  }
                  title={
                    <div
                      style={{
                        // maxWidth: chat.type === "createNew" ? "" : "10rem",
                        width: "100%",
                        textAlign: chat.type === "createNew" ? "center" : "right",
                        float: chat.type === "createNew" ? "center" : "right",
                        overflow: "hidden",
                        paddingRight: chat.type === "createNew" ? "0rem" : "1rem",
                      }}
                    >
                      {chat.type === "createNew" ? "+ create new chat" : formatChatUsers(chat)}
                    </div>
                  }
                />
                {chat.type !== "createNew" ? (
                  <Button
                    icon={chatListMode === "hidden" ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    size="small"
                    onClick={() => hideChat(chat.id)}
                  />
                ) : null}
              </List.Item>
            )}
          />
        </div>
        <Card
          bordered={true}
          style={{
            background: "rgb(40, 40, 40)",
            gridColumnStart: width > 1023 ? 2 : 1,
            gridColumnEnd: 8,
            gridRowStart: width > 1023 ? 1 : 2,
            gridRowEnd: 6,
            overflow: "auto",
          }}
        >
          <h2>{formatChatUsers(currentChat)}</h2>
          <Content
            id="scrollableDiv"
            style={{
              // height: "100%",
              minHeight: width > 1023 ? "57vh" : "45vh",
              maxHeight: width > 1023 ? "57vh" : "45vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={messages.length} //This is important field to render the next data
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
              next={() => {
                if (messages.length > 0) {
                  fetchChatMessages();
                  return messages;
                }
                return [];
              }}
              inverse={true}
              scrollableTarget="scrollableDiv"
              hasMore={moreMessages}
              endMessage={
                <div style={{ textAlign: "center" }}>
                  notifi.xyz
                  <hr />
                </div>
              }
            >
              {messages.length > 0 &&
                messages.map((el, index) => {
                  const ensData = (el.user && el.user.ensData) || {};
                  const ethAddress = (el.user && el.user.ethAddress) || "";
                  const username = ensData.name || formatShortEthAddress(ethAddress);
                  const avatar = ensData.avatar;
                  let align = "left";
                  if (el.type === "bot") {
                    return (
                      <div style={{ textAlign: "center", fontWeight: "bold" }} key={index}>
                        {el.message}
                        <hr />
                      </div>
                    );
                  } else if (el.userId === user.id) {
                    align = "right";
                  }
                  return messageCard(index, avatar, ethAddress, username, el, align);
                })}
            </InfiniteScroll>
          </Content>

          {currentChat ? (
            <Form
              form={form}
              layout="inline"
              onFinish={sendMessage}
              style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}
            >
              <Form.Item name="message" style={{ width: "70%" }}>
                <Input
                  autoComplete="off"
                  autoFocus="autofocus"
                  placeholder="Type something..."
                  style={{ marginLeft: 10 }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" style={{ marginLeft: 5, backgroundColor: "#78a2cc" }} htmlType="submit">
                  Send
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <p>Select a chat from the side bar or start a new one</p>
          )}
        </Card>
      </Card>
    </div>
  );
}
