import io from "socket.io-client2";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Card, Image, List, Popover } from "antd";
import { GithubOutlined, GlobalOutlined, MailOutlined, SettingOutlined, TwitterOutlined } from "@ant-design/icons";
import { getAvatar } from "../helpers/ens.js";
import { formatShortEthAddress } from "../helpers/utils.js";

export default function Profile({
  apiUrl,
  findOrCreateUser,
  provider,
  setIsOnChainMessage,
  setSendMessageModalOpen,
  setSendMessageRecipient,
  user,
  width,
}) {
  const [avatar, setAvatar] = useState("notifi_logo_192.png");
  const [userDetails, setUserDetails] = useState({});
  const [nftCollection, setNftCollection] = useState([]);
  const [ethAddress, setEthAddress] = useState();
  const [preferredContactMethod, setPreferredContactMethod] = useState();
  const [contactMethod, setContactMethod] = useState({});
  const [isUserProfile, setIsUserProfile] = useState(false);
  const [sendMessageHover, setSendMessageHover] = useState(false);
  const [dmHover, setDmHover] = useState(false);
  const [openSeaUser, setOpenSeaUser] = useState({});
  const [tokens, setTokens] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const defaultEthAddress = window.location.pathname.split("/")[2];
    if (defaultEthAddress) {
      // TODO if defaultEthAddress is an ens name, lookup ethAddress
      setEthAddress(defaultEthAddress.toLowerCase());
    }
    if (user && !defaultEthAddress) {
      setIsUserProfile(true);
    }
  }, []);

  useEffect(() => {
    const defaultContactMethodText = (
      <div>
        <p>No associated accounts found.</p>
        <p>Try getting their attention by sending an on chain message to their wallet.</p>
      </div>
    );
    const twitterContactMethodText = (
      <div>
        <p>Associated Twitter account found!</p>
        <p>Try reaching them on Twitter or sending an on chain message to their wallet.</p>
      </div>
    );
    const twitterButtonText = "Message them on Twitter";
    const emailContactMethodText = (
      <div>
        <p>Associated email account found!</p>
        <p>Try reaching them by email or sending an on chain message to their wallet.</p>
      </div>
    );
    const emailButtonText = "Send an email";

    if (!preferredContactMethod) {
      if (userDetails) {
        if (userDetails.twitter) {
          setContactMethod({
            message: twitterContactMethodText,
            buttonText: twitterButtonText,
            url: `https://twitter.com/${userDetails.twitter}`,
          });
          return;
        }
        if (userDetails.email) {
          setContactMethod({
            message: emailContactMethodText,
            buttonText: emailButtonText,
            url: `mailto:${userDetails.email}`,
          });
          return;
        }
      }
      const twitterQuery = userDetails.name || ethAddress;
      setContactMethod({
        message: defaultContactMethodText,
        buttonText: "Search for address on Twitter",
        url: `https://twitter.com/search?q=${twitterQuery}&src=typed_query`,
      });
    }
  }, [preferredContactMethod, userDetails, ethAddress]);

  useEffect(() => {
    if (user) {
      if (user.ethAddress === ethAddress) {
        setIsUserProfile(true);
      }
      if (isUserProfile) {
        if (user.ensData) {
          setUserDetails(user.ensData);
          if (user.ensData.avatar) {
            setAvatar(user.ensData.avatar);
          }
        }
        setEthAddress(user.ethAddress);
        setPreferredContactMethod(user.preferredContactMethod);
      }
    }
  }, [user, isUserProfile]);

  useEffect(async () => {
    if (ethAddress) {
      openSeaUser.url = `https://opensea.io/${ethAddress}`;
      setOpenSeaUser(openSeaUser);
      if (!nftCollection.length) {
        fetchNFTCollection();
      }
      history.push(`/profile/${ethAddress}`);
      if (!isUserProfile) {
        const newUser = await findOrCreateUser(ethAddress);
        if (newUser && newUser.ensData) {
          setUserDetails(newUser.ensData);
          if (newUser.ensData.avatar && newUser.ensData.avatar.startsWith("http")) {
            setAvatar(newUser.ensData.avatar);
          }
          getAvatar(provider, newUser.ensData.name).then(av => av && setAvatar(av));
        }
      }
    }

    getAssets();
  }, [ethAddress]);

  let socket;
  const io_options = {
    transports: ["websocket"],
    timeout: 6000,
    reconnection: false,
    reconnectionAttempts: 0,
    query: {
      api_token: process.env.ZERION_API_KEY || "Demo.ukEVQp6L5vfgxcz4sBke7XvS873GMYHy",
    },
  };

  function getAssets() {
    const namespace = "address";
    if (!socket) {
      socket = io(`wss://api-v4.zerion.io/${namespace}`, io_options);
    }
    const model = "assets";
    const requestBody = {
      scope: [model],
      payload: {
        address: ethAddress,
        currency: "usd",
      },
    };

    socket.emit("get", requestBody);
    socket.on(`received ${namespace} ${model}`, data => {
      const assetMap = data && data.payload && data.payload.assets;
      if (assetMap) {
        let totalValue = 0;
        const assets = Object.keys(assetMap)
          .map(key => {
            const symbol = assetMap[key].asset.symbol;
            const value = ethers.utils.formatUnits(assetMap[key].quantity, assetMap[key].asset.decimals);
            const usdValue = assetMap[key].asset.price ? assetMap[key].asset.price.value * value : 0;
            totalValue += usdValue;
            return { symbol, value, usdValue };
          })
          .filter(asset => asset.usdValue > 1)
          .sort((asset1, asset2) => asset2.usdValue - asset1.usdValue)
          .map(asset => {
            asset.value = Number.parseFloat(asset.value);
            asset.usdValue = formatNumber(asset.usdValue);
            return asset;
          });
        setTokens(assets);
        setPortfolioValue(formatNumber(totalValue));
      }
    });
  }

  function formatNumber(strNum, decimalPlaces = 2) {
    return strNum.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const isUserDefaultDescription = () => {
    return (
      <p>
        Write something about yourself in the description field on the{" "}
        <a href={`https://app.ens.domains/name/${userDetails.name}/details`} target="_blank">
          ENS dashboard
        </a>{" "}
        and it will be displayed here.
      </p>
    );
  };

  const notUserDefaultDescription = () => {
    return (
      <div>
        <p>This person has not filled out their ENS description.</p>
        <p style={{ marginBottom: "0rem" }}>
          To find out more about them, check the links to the left, or try{" "}
          <a href={`https://twitter.com/search?q=${userDetails.name || ethAddress}&src=typed_query`} target="_blank">
            searching for them on Twitter
          </a>
          .
        </p>
      </div>
    );
  };

  const sendMessage = isOnChain => {
    if (isOnChain) {
      setIsOnChainMessage(true);
    } else {
      setIsOnChainMessage(false);
    }
    setSendMessageRecipient(ethAddress);
    setSendMessageModalOpen(true);
  };

  // TODO handle nft pagination
  async function fetchNFTCollection(offset = 0) {
    if (ethAddress) {
      const url = `${apiUrl}/api/profile/nfts/${ethAddress}?offset=0`;
      const res = await fetch(url).catch(error => {
        console.error(error);
        return { openSeaUser: {}, nftCollection: { assets: [] } };
      });
      const { openSeaUser: foundOpenSeaUser, nftCollection: newNftCollection } = await res.json();
      openSeaUser.username = (foundOpenSeaUser && foundOpenSeaUser.username) || "";
      setOpenSeaUser(openSeaUser);
      if (newNftCollection && newNftCollection.assets) {
        setNftCollection(nftCollection => [...nftCollection, ...newNftCollection.assets]);
      }
    }
  }

  function formatUserHeader(userDetails) {
    return (
      <div
        style={{
          float: "right",
          gridColumnStart: 2,
          gridColumnEnd: 4,
          gridRowStart: 1,
          gridRowEnd: width > 1023 ? 2 : 2,
        }}
      >
        <Card
          style={{
            height: "100%",
            marginBottom: "1rem",
            background: "rgb(40, 40, 40)",
          }}
          bodyStyle={{ height: "100%" }}
        >
          <h3 style={{ display: "inline-block", overflow: "auto" }}>{contactMethod.message}</h3>
          <div
            style={{
              marginBottom: "-1rem",
            }}
          >
            <div style={{ overflow: "auto" }}>
              {contactMethod.url && contactMethod.buttonText && (
                <Button size="medium" href={contactMethod.url} target="_blank">
                  <div style={{ display: "inline-block", float: "center" }}>{contactMethod.buttonText}</div>
                </Button>
              )}{" "}
              {user ? (
                <Button size="medium" onClick={() => sendMessage(true)}>
                  <div style={{ display: "inline-block", float: "center" }}>Send a message on chain</div>
                </Button>
              ) : (
                <Popover
                  style={{ width: 500 }}
                  content="Connect wallet to send a message"
                  trigger="hover"
                  visible={sendMessageHover}
                  onVisibleChange={() => setSendMessageHover(!sendMessageHover)}
                >
                  <Button disabled size="medium" onClick={() => sendMessage(true)}>
                    <div style={{ display: "inline-block", float: "center" }}>Send a message on chain</div>
                  </Button>
                </Popover>
              )}
            </div>
            {/* {isUserProfile && (
              <p style={{ marginTop: "1rem", marginBottom: "0rem" }}>
                <Link to="/settings">Change your preferred contact method.</Link>
              </p>
            )} */}
          </div>
        </Card>
      </div>
    );
  }

  function formatUserDetails(userDetails) {
    return (
      <Card
        style={{
          display: "inline-block",
          float: "left",
          background: "rgb(40, 40, 40)",
          height: "100%",
          gridRowStart: 1,
          gridRowEnd: width > 1023 ? 4 : 3,
          gridColumnStart: 1,
        }}
      >
        <div>
          <Image style={{ maxWidth: "12rem" }} src={avatar} />
        </div>
        <div
          style={{
            float: "left",
            display: "inline-block",
            position: "relative",
            paddingTop: "1rem",
            width: "100%",
            marginBottom: "-1rem",
          }}
        >
          {isUserProfile && (
            <p style={{ textAlign: "center" }}>
              <Button size="medium" style={{ width: "100%" }}>
                <a href={`https://app.ens.domains/name/${userDetails.name}/details`} target="_blank">
                  <SettingOutlined /> Edit Profile
                </a>
              </Button>
            </p>
          )}
          <p style={{ textAlign: "center" }}>
            {user && !isUserProfile && (
              <Button size="medium" style={{ width: "100%" }} onClick={() => sendMessage()}>
                <div style={{ display: "inline-block", float: "center" }}>Direct Message</div>
              </Button>
            )}
          </p>
          <div style={{ textAlign: "left" }}>
            {ethAddress && (
              <p>
                <img src="/etherscan-logo-light-circle.svg" width="14px" height="14px" />{" "}
                <a href={`https://etherscan.io/address/${ethAddress}`} target="_blank">
                  {formatShortEthAddress(ethAddress, 8)}
                </a>
              </p>
            )}
            {userDetails.email && (
              <p>
                <MailOutlined />{" "}
                <a href={`mailto:${userDetails.email}`} target="_blank">
                  {userDetails.email}
                </a>
              </p>
            )}
            {userDetails.twitter && (
              <p>
                <TwitterOutlined />{" "}
                <a href={`https://twitter.com/${userDetails.twitter}`} target="_blank">
                  {userDetails.twitter}
                </a>
              </p>
            )}
            {userDetails.discord && (
              <p>
                <img src="/Discord-Logo-White.svg" width="14px" height="14px" /> {userDetails.discord || "N/A"}
              </p>
            )}
            {openSeaUser.url && (
              <p>
                <img src="/opensea_logo.svg" width="14px" height="14px" />{" "}
                <a href={openSeaUser.url} target="_blank">
                  {openSeaUser.username || formatShortEthAddress(ethAddress, 8)}
                </a>
              </p>
            )}
            {userDetails.github && (
              <p>
                <GithubOutlined />{" "}
                <a href={`https://github.com/${userDetails.github}`} target="_blank">
                  {userDetails.github}
                </a>
              </p>
            )}
            {userDetails.url && (
              <p>
                <GlobalOutlined />{" "}
                <a href={userDetails.url} target="_blank">
                  {userDetails.url}
                </a>
              </p>
            )}
            {userDetails.name && (
              <p>
                <img src="/ens_logo.svg" width="14px" height="14px" />{" "}
                <a href={`https://app.ens.domains/address/${ethAddress}`} target="_blank">
                  {userDetails.name}
                </a>
              </p>
            )}
          </div>
        </div>
      </Card>
    );
  }

  function formatNftCollection() {
    return (
      <Card
        bordered={false}
        style={{ marginTop: "0rem" }}
        bodyStyle={{
          display: "grid",
          gridGap: "1rem",
          gridTemplateColumns: "repeat( auto-fit, minmax(12rem, 1fr) )",
          padding: "0rem",
        }}
      >
        {nftCollection.map((nft, index) => {
          return (
            <Card
              key={index}
              bordered={true}
              style={{
                background: "rgb(40, 40, 40)",
                textAlign: "-webkit-center",
                gridColumnStart: index === 0 ? 1 : null,
              }}
              bodyStyle={{}}
            >
              <Image style={{ maxHeight: "8rem" }} src={nft.image_preview_url} preview={{ src: nft.image_url }} />
              <a href={nft.permalink} target="_blank">
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "10rem",
                  }}
                >
                  {nft.name}
                </div>
              </a>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "10rem",
                }}
              >
                {nft.collection.name}
              </div>
            </Card>
          );
        })}
      </Card>
    );
  }

  return (
    <Card
      bordered={false}
      style={{
        marginTop: "1rem",
        boxSizing: "border-box",
        maxWidth: width > 1023 ? "80%" : "100%",
        margin: "0 auto",
        paddingBottom: "3.2rem",
      }}
    >
      <h1
        style={{
          display: "inline-block",
          textAlign: "center",
          marginTop: "-1rem",
          marginBottom: "-1rem",
          fontSize: "2.5rem",
          width: "100%",
        }}
      >
        <div style={{ overflow: "hidden", maxWidth: "90%", textOverflow: "ellipsis", display: "inline-block" }}>
          {userDetails.name ? userDetails.name : openSeaUser.username || ethAddress}
        </div>{" "}
        <a
          href={`https://etherscan.io/address/${ethAddress}`}
          target="_blank"
          style={{ minWidth: "3%", verticalAlign: "top" }}
        >
          <img src={"etherscan-logo-light-circle.svg"} style={{ maxHeight: "1.5rem" }} />
        </a>
      </h1>
      <div
        style={{
          display: "grid",
          gridGap: "1rem",
        }}
      >
        {formatUserHeader(userDetails)}
        {formatUserDetails(userDetails)}
        <Card
          style={{
            float: "left",
            display: "inline-block",
            background: "rgb(40, 40, 40)",
            gridRowStart: 2,
            gridColumnStart: 2,
            gridColumnEnd: width > 1023 ? 3 : 4,
            height: "100%",
            width: "100%",
            textAlign: "center",
            maxHeight: "16rem",
            overflow: "auto",
          }}
        >
          <h1>About</h1>
          {userDetails.description || (isUserProfile ? isUserDefaultDescription() : notUserDefaultDescription())}
        </Card>
        {width > 1023 && (
          <Card
            style={{
              float: "left",
              display: "inline-block",
              background: "rgb(40, 40, 40)",
              gridColumnStart: 2,
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            <h1>Wallets</h1>
            <div>
              Ethereum: <div style={{ overflowWrap: "anywhere" }}>{ethAddress}</div>
            </div>
            {/* <p style={{ overflowWrap: "anywhere" }}>Bitcoin: N/A</p>
          <p style={{ overflowWrap: "anywhere" }}>Dogecoin: N/A</p> */}
          </Card>
        )}
        <Card
          style={{
            float: "left",
            display: "inline-block",
            background: "rgb(40, 40, 40)",
            gridRowStart: width > 1023 ? 2 : 3,
            gridRowEnd: 4,
            gridColumnStart: width > 1023 ? 3 : 1,
            gridColumnEnd: 4,
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <h1>Assets</h1>
          <h2>Total portfolio value: ${portfolioValue}</h2>
          {/* <br /> */}
          {tokens && (
            <List
              // bordered={true}
              style={{ maxHeight: "16rem", overflow: "auto" }}
              itemLayout="horizontal"
              dataSource={tokens}
              renderItem={token => (
                <List.Item>
                  <List.Item.Meta
                    title={token.symbol}
                    description={
                      <p style={{ marginBottom: "0rem" }}>
                        <b>{token.value}</b> (${token.usdValue})
                      </p>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
      </div>
      {nftCollection.length ? formatNftCollection() : null}
    </Card>
  );
}
